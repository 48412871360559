import React from 'react';
import { bool } from 'prop-types';

import LocationDistanceFilterPlain from './LocationDistanceFilterPlain';
import LocationDistanceFilterPopup from './LocationDistanceFilterPopup';

const LocationDistanceFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <LocationDistanceFilterPopup {...rest} />
  ) : (
    <LocationDistanceFilterPlain {...rest} />
  );
};

LocationDistanceFilter.defaultProps = {
  showAsPopup: false,
};

LocationDistanceFilter.propTypes = {
  showAsPopup: bool,
};

export default LocationDistanceFilter;
